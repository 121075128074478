import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "..";
import {FilterModel, ProductOptionModel} from "../features/report/laporanKartuStock/laporanKartuStock.model";
import globalApi from "./global.api";
import {
  AdminStockistModel,
  CityModel,
  CollectionTagModel,
  DistrictModel,
  ExpeditionModel, GetPartnerWithTypeDropdownRequest, ListDeliveredWithFilterRequest,
  PartnerModel,
  PaymentMethodModel,
  PendingNotificationModel,
  ProductCategoryModel, ProductDropdownRequest,
  ProductModel,
  ProvinceModel,
  RegisterFirebaseTokenModel,
  RoleModel,
  UOMModel,
  UserModel,
  VariationModel,
  WarehouseModel,
} from "./global.model";

export interface GlobalSlice {
  listProduct?: ProductModel[];
  listProductDropdown?: ProductOptionModel[];
  listActiveProduct?: ProductModel[];
  listProductCategory?: ProductCategoryModel[];
  listUser?: UserModel[];
  listCollectionTag?: CollectionTagModel[];
  listRole?: RoleModel[];
  listProvince?: ProvinceModel[];
  listCity?: CityModel[];
  listCityByProvince?: CityModel[];
  listDistrictByCity?: DistrictModel[];
  listWarehouse?: WarehouseModel[];
  listUOM?: UOMModel[];
  listVariationForUOM?: VariationModel[];
  listPartner?: PartnerModel[];
  listExpedition?: ExpeditionModel[];
  listPaymentMethod?: PaymentMethodModel[];
  listAdminStockist?: AdminStockistModel[];
  listPartnerByType?: PartnerModel[];
  pendingNotification?: PendingNotificationModel;
  filterStockReport? : FilterModel;

  error?: ApiErrorResponse<any>;
  status?: string;
}

export const registerFirebaseToken = createAsyncThunk(
  "GlobalState/registerFirebaseToken",
  async (model : RegisterFirebaseTokenModel, { rejectWithValue }) => {
    try {
      return await globalApi.registerFirebaseToken(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListUser = createAsyncThunk(
  "GlobalState/getListUser",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListUser();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListRole = createAsyncThunk(
  "GlobalState/getListRole",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListRole();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListProduct = createAsyncThunk(
  "GlobalState/getListProduct",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListProduct();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProductDropdown = createAsyncThunk(
    "GlobalState/getProductDropdown",
    async (args: ProductDropdownRequest, { rejectWithValue }) => {
      try {
        return await globalApi.getProductDropdown(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getPartnerDropdown = createAsyncThunk(
    "GlobalState/getPartnerDropdown",
    async (partnerName: string, { rejectWithValue }) => {
      try {
        return await globalApi.getPartnerDropdown(partnerName);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getPartnerWithTypeDropdown = createAsyncThunk(
    "GlobalState/getPartnerWithTypeDropdown",
    async (args: GetPartnerWithTypeDropdownRequest, { rejectWithValue }) => {
      try {
        return await globalApi.getPartnerWithTypeDropdown(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getListDeliveredWithFilter = createAsyncThunk(
    "GlobalState/getListDeliveredWithFilter",
    async (args: ListDeliveredWithFilterRequest, { rejectWithValue }) => {
      try {
        return await globalApi.getListDeliveredWithFilter(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getListActiveProduct = createAsyncThunk(
  "GlobalState/getListActiveProduct",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListActiveProduct();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListCollectionTag = createAsyncThunk(
  "GlobalState/getListCollectionTag",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListProduct();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListCity = createAsyncThunk(
  "GlobalState/getListCity",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListCity();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWarehouse = createAsyncThunk(
  "GlobalState/getListWarehouse",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListWarehouse();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListUOM = createAsyncThunk(
  "GlobalState/getListUOM",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListUOM();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListProductCategory = createAsyncThunk(
  "GlobalState/getListProductCategory",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListProductCategory();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationForUOM = createAsyncThunk(
  "GlobalState/getListVariationForUOM",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListVariationForUOM();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListPartner = createAsyncThunk(
  "GlobalState/getListPartner",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListPartner();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProvince = createAsyncThunk(
  "ProvinceState/Province",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getProvince();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getCityByProvince = createAsyncThunk(
  "CityState/City",
  async (provinceId: string, { rejectWithValue }) => {
    try {
      return await globalApi.getCityByProvince(provinceId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getDistrictByCity = createAsyncThunk(
  "DistrictState/District",
  async (cityId: string, { rejectWithValue }) => {
    try {
      return await globalApi.getDistrictByCity(cityId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListExpedition = createAsyncThunk(
  "GlobalState/getListExpedition",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListExpedition();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListPaymentMethod = createAsyncThunk(
  "GlobalState/getListPaymentMethod",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListPaymentMethod();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListAdminStockist = createAsyncThunk(
  "GlobalState/getListAdminStockist",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await globalApi.getListAdminStockist();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListPartnerByType = createAsyncThunk(
  "GlobalState/getListPartnerByType",
  async (type: number, { rejectWithValue }) => {
    try {
      return await globalApi.getListPartnerByType(type);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPendingNotification = createAsyncThunk(
  "GlobalState/getPendingNotification",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await globalApi.getPendingNotification();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const GlobalSlice = createSlice({
  name: "GlobalState",
  initialState: {} as GlobalSlice,
  reducers: {
    setFilterStockReport: (state, action) => {
      state.filterStockReport = action.payload;
    }
  },
  extraReducers: (builder) => {
    //user
    builder.addCase(getListUser.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListUser.fulfilled, (state, { payload }) => {
      state.listUser = payload;
    });
    builder.addCase(getListUser.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //product
    builder.addCase(getListProduct.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListProduct.fulfilled, (state, { payload }) => {
      state.listProduct = payload;
    });
    builder.addCase(getListProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //product dropdown
    builder.addCase(getProductDropdown.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getProductDropdown.fulfilled, (state, { payload }) => {
      state.listProductDropdown = payload.map((item: any) => {
        return {
            value: item.id,
            label: item.name + " - " + item.inventorySKU
        }
      });
    });
    builder.addCase(getProductDropdown.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(getListActiveProduct.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListActiveProduct.fulfilled, (state, { payload }) => {
      state.listActiveProduct = payload;
    });
    builder.addCase(getListActiveProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //product category
    builder.addCase(getListProductCategory.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListProductCategory.fulfilled, (state, { payload }) => {
      state.listProductCategory = payload;
    });
    builder.addCase(getListProductCategory.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //collection Tag
    builder.addCase(getListCollectionTag.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListCollectionTag.fulfilled, (state, { payload }) => {
      state.listCollectionTag = payload;
    });
    builder.addCase(getListCollectionTag.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //role
    builder.addCase(getListRole.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListRole.fulfilled, (state, { payload }) => {
      state.listRole = payload;
    });
    builder.addCase(getListRole.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //province
    builder.addCase(getProvince.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getProvince.fulfilled, (state, { payload }) => {
      state.listProvince = payload;
    });
    builder.addCase(getProvince.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });

    //city
    builder.addCase(getListCity.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListCity.fulfilled, (state, { payload }) => {
      state.listCity = payload;
    });
    builder.addCase(getListCity.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //warehouse
    builder.addCase(getListWarehouse.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListWarehouse.fulfilled, (state, { payload }) => {
      state.listWarehouse = payload;
    });
    builder.addCase(getListWarehouse.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //uom
    builder.addCase(getListUOM.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListUOM.fulfilled, (state, { payload }) => {
      state.listUOM = payload;
    });
    builder.addCase(getListUOM.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //variation
    builder.addCase(getListVariationForUOM.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListVariationForUOM.fulfilled, (state, { payload }) => {
      state.listVariationForUOM = payload;
    });
    builder.addCase(getListVariationForUOM.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //partner
    builder.addCase(getListPartner.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListPartner.fulfilled, (state, { payload }) => {
      state.listPartner = payload;
    });
    builder.addCase(getListPartner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //city by province
    builder.addCase(getCityByProvince.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getCityByProvince.fulfilled, (state, { payload }) => {
      state.listCityByProvince = payload;
    });
    builder.addCase(getCityByProvince.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //district by city
    builder.addCase(getDistrictByCity.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getDistrictByCity.fulfilled, (state, { payload }) => {
      state.listDistrictByCity = payload;
    });
    builder.addCase(getDistrictByCity.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //expedition
    builder.addCase(getListExpedition.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListExpedition.fulfilled, (state, { payload }) => {
      state.listExpedition = payload;
    });
    builder.addCase(getListExpedition.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //expedition
    builder.addCase(getListPaymentMethod.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListPaymentMethod.fulfilled, (state, { payload }) => {
      state.listPaymentMethod = payload;
    });
    builder.addCase(getListPaymentMethod.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //admin Stockist
    builder.addCase(getListAdminStockist.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListAdminStockist.fulfilled, (state, { payload }) => {
      state.listPaymentMethod = payload;
    });
    builder.addCase(getListAdminStockist.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
    //admin Stockist
    builder.addCase(getListPartnerByType.pending, (state) => {
      state.status = "";
    });
    builder.addCase(getListPartnerByType.fulfilled, (state, { payload }) => {
      state.listPartnerByType = payload;
    });
    builder.addCase(getListPartnerByType.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getPendingNotification.pending, (state) => {
      // state.status = "";
    });
    builder.addCase(getPendingNotification.fulfilled, (state, { payload }) => {
      state.pendingNotification = payload;
    });
    builder.addCase(getPendingNotification.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const {setFilterStockReport} = GlobalSlice.actions;
export default GlobalSlice.reducer;
