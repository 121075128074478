import axios from "axios";
import { NonNullChain } from "typescript";
import { endpoint } from "../../../constants";
import {
  CheckOutAdminRequestModel,
  CreateSalesOrderAdminModel,
  GetVariationByProductAndUserRequest,
  SalesOrderDetailModel,
  SalesOrderModel,
  ShipmentModel,
  UnpaidDropshipFilterModel,
  UploadImageModel,
} from "./salesOrder.model";

export default class Api {
  static createData(model: SalesOrderModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/Create", model)
      .then((res) => res.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetList")
      .then((res) => res.data.data);
  }

  static getListVariationByProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .then((res) => res.data.data);
  }

  static getListVariationByProductAndUser(model : GetVariationByProductAndUserRequest) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProductAndUser?productId=" + model.productId +"&partnerType="+model.partnerType+"&userId="+model.userId)
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: SalesOrderModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/update", model)
      .then((res) => res.data);
  }



  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static cancelOrder(id: string, adminId: string) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CancelOrder", null, {
        params: {
            salesOrderId: id,
            adminId: adminId
        }
      })
      .then((res) => [{ response: res.data.data }]);
  }

  static createSalesOrderDetail(model: SalesOrderDetailModel) {
    return axios
      .post<any>(endpoint + "/api/salesOrderDetail/Create", model)
      .then((res) => res.data);
  }

  static createShipment(model: ShipmentModel) {
    return axios
      .post<any>(endpoint + "/api/shipment/Create", model)
      .then((res) => res.data);
  }

  static getAddressListByPartner(id: string | null) {
    return axios
      .get<any>(endpoint + "/api/Address/GetListByPartner?partnerId=" + id)
      .then((res) => res.data.data);
  }

  static getListPartner() {
    return axios.get<any>(endpoint + "/api/Partner/GetList").then((res) => res.data.data);
  }

  static getListPaymentMethod() {
    return axios.get<any>(endpoint + "/api/PaymentMethod/GetList").then((res) => res.data.data);
  }

  static getListPaymentMethodByType(type : number) {
    return axios.get<any>(endpoint + "/api/PaymentMethod/GetListByType?partnerType="+type).then((res) => res.data.data);
  }

  static checkCostAdmin(model: CheckOutAdminRequestModel) {
    return axios
      .post<any>(endpoint + "/api/RajaOngkir/CheckCostAdmin", model)
      .then((res) => res.data);
  }

  static CreateSalesOrderAdmin(model: CreateSalesOrderAdminModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/CheckOutAdmin", model)
      .then((res) => res.data);
  }

  static getListUnpaidDropship(model: UnpaidDropshipFilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListUnpaidDropship", model)
      .then((res) => res.data);
  }

  static getComplaintDetailItemBySONumber(soNumber: string) {
    return axios
      .get<any>(endpoint + "/api/Complaint/GetItemDetailBySoNumber?SONumber=" + soNumber)
      .then((res) => res.data.data.complaintDetail);
  }

  static getReturById(id: string) {
    return axios
      .get<any>(endpoint + "/api/Retur/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static uploadImage(model: UploadImageModel) {
    return axios
        .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
        .then((res) => res);
}

}
