
export interface SalesOrderModel {
  orderDate: Date;
  paymentMethodId: string;
  statusOrder: number;
  shipmentCost: number;
  discount: number;
  discountPercentage: number;
  discountType: number;
  note: string;
  soNumber: string;
  transactionStatus: number;
  subTotal: number;
  grandTotal: number;
  qtyTotal: number;
  totalTax: number;
  taxPercentage: number;
  taxStatus: number;
  partnerId: string;
  orderType: number;
  adminFee: number;
  
}

export interface ShipmentModel {
  deliveryName: string;
  shippingAddress: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhone: string;
  recipientProvince: string;
  recipientCity: string;
  recipientSubDistrict: string;
  recipientEmail: string;
  resiNumber: string;
  totalWeight: number;
  shippingCost: number;
  originalShippingCost : number;
  totalShippingCost: number;
  expeditionId: string;
  tarifType: number;
  deliveryDate: Date;
  salesOrderId: string;
  administrationPrice: number;
  note: string;
  service: string;
}

export interface AddressModel {
  id: string;
  name: string;
  phone: string;
  postalCode: string;
  fullAddress: string;
  addressWithLocation: string;
  sender: string;
  description: string;
  isDefault: true;
  partnerId: string;
  provinceId: string;
  provinceNo: 0;
  cityId: string;
  cityNo: 0;
  districtId: string;
  districtNo: 0;
  countryId: string;
  provinceName: string;
  cityName: string;
  districtName: string;
}

export interface CreateSalesOrderModel {
  salesOrder: SalesOrderModel;
  shipment: ShipmentModel;
  salesOrderDetail: SalesOrderDetailModel[];
}

export interface SalesOrderDetailModel {
  id: string;
  salesOrderId: string;
  variationId: string;
  variationName: string;
  productId: string;
  productName: string;
  quantity: number;
  note: string;
  price: number;
  priceSatuan: number;
  stockQty: number;
  weight : number;
}

export interface PartnerModel {
  id: string;
  userId: string;
  type: PartnerType;
  fullName: string;
  email: string;
  isActive : boolean;
}

export enum PartnerType {
  Customer,
  Stockist,
  Reseller,
  Admin,
  AdminStockist
}

export enum ThirdPartyTypeEnum {
  None,
  Lazada,
  Tokopedia,
  Shopee,
  Blibli,
  BukaLapak,
  JDid,
  Orami,
  TikTok,
  Other,
}

export interface PartnerOptionModel {
  value: string;
  label: string;
  type: PartnerType;
}

export interface PaymentMethodModel {
  id: string;
  name: string;
}

export interface CheckOutAdminRequestModel {
  senderCityId : string;
  destinationDistrictId : string;
  salesOrderDetailList : CheckOutDetail[];
}

export interface CheckOutDetail {
  variationId : string;
  quantity : number;
}

export interface ExpeditionServiceCostModel {
  expeditionId : string;
  code : string;
  name  : string;
  costs : ServiceModel[];
}

export interface ServiceModel {
  service : string;
  description : string;
  cost : CostModel[];
}

export interface CostModel {
  value : number;
  etd : string;
}

export interface CreateSalesOrderAdminModel {
  salesOrder : SalesOrderData;
  salesOrderDetail : SalesOrderDetailModel[];
  shipment : ShipmentData | null;
}

export interface SalesOrderData {
  customerId? : string | null;
  thirdParty : number;
  statusOrder : number;
  paymentType : number;
  paymentMethodId : string;
  discount : number;
  discountPercentage : number;
  taxAmount : number;
  taxPercentage : number;
  note : string;
  adminId : string;
  returId? : string | null;
  barcodeImage? : string;
}

export interface ShipmentData {
  senderName: string;
  senderAddress: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhone: string;
  recipientProvinceId: string;
  recipientCityId: string;
  recipientDistrictId: string;
  expeditionId?: string|null;
  service?: string;
  resi: string;
  isFree: boolean;
  totalShippingCost? : number;
}

export interface UnpaidDropshipFilterModel {
  customerName: string;
  productName: string;
  productCode: string;
  startDate: string;
  endDate: string;
  soNumber: string;
  skip: number;
  take: number;
}

export interface GetVariationByProductAndUserRequest {
  productId : string;
  userId: string;
  partnerType : number;
}

export interface ReturModel {
  number : string;
  soNumber : string;
  noteRetur : string;
  salesOrderId : string;
  partnerType: number;
  partnerId : string;
  customerName : string;
}

export interface ComplainDetailItem{
  id : string;
  complaintId : string;
  variationId : string;
  quantity: number;
  variantName : string;
  productName : string;
  productCode: string;
  inventorySKU : string;
  price : number;
  weight : number;
}

export interface UploadImageModel {
  file_name: string,
  base64url: string,
  imagepath: string,
  location: string
}
